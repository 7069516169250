<template lang="html">
  <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
    <vx-card title="" class="formulario">
    <span class="card__header"><h4 class="titulo">Consultas a Reportes de Crédito bajo su supervisión</h4></span>
      <div class="row w-full">
        
          <div class="col-md-6">
            <span class="span-placeholder" for="ejecutivos">Filtra Consultas por ejecutivo</span>
            <select name="eject" id="ejecutivos" v-model="ejecutivo" class="vs-input" @change="listaBurosEjecutivo()">
              <option :value='tipo.EMail' v-for="tipo in ejecutivos"  :key="tipo.EMail">{{tipo.Nombre}}</option>
            </select>
          </div>
  
        <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="12" vs-xs="12" vs-offset="1">
          <div class="vx-row w-full">
            <span class="span-placeholder" for="organizaciones">Filtra Consultas por organizacion</span>
            <select name="org" id="organizaciones" v-model="organizacion" class="vs-input" @change="listaBurosOrganizacion()">
              <option :value='tipo.Id' v-for="tipo in organizaciones"  :key="tipo.Id">{{tipo.Nombre}}</option>
            </select>
          </div>
        </vs-col> -->
      </div>
    <br>
      <vs-table :data="reportes" max-items="10"
        pagination
        search
        noDataText="No hay datos para mostrar"
        class="table-custom"
        id="tabla" >
        <template slot="thead">
          <vs-th>
            Fecha
          </vs-th>
          <vs-th>
            Solicitante
          </vs-th>
          <vs-th>
            Broker
          </vs-th>
          <vs-th>
            Supervisor
          </vs-th>
          <vs-th>
            Organización
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].Fecha" >
              {{data[indextr].Fecha}}
            </vs-td>
            <vs-td :data="data[indextr].Solicitante">
              {{data[indextr].Solicitante}}
            </vs-td>
            <vs-td :data="data[indextr].Broker">
              {{data[indextr].Broker}}
            </vs-td>
            <vs-td :data="data[indextr].Supervisor">
              {{data[indextr].Supervisor}}
            </vs-td>
            <vs-td :data="data[indextr].Organizacion">
              {{data[indextr].Organizacion}}
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      reportes:[],
      ejecutivo:'',
      ejecutivos:[],
      // organizacion:'',
      // organizaciones:[],
    }
  },
  mounted(){
    this.$vs.loading({
        container: '#tabla',
        scale: 0.6
      })
    this.getApikey()
    this.listaSupervisados()
    // this.listaOrganizaciones()
  },
  methods:{
    listaBurosOrganizacion(){
      this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })
      let self=this
      this.reportes=[]
      this.ejecutivo={}
      var objRequestListaBurosOrganizacion = {
        strApiKey: this.key,
        strMetodo: 'ListaBurosOrganizacion',
        objOrganizacion: {
           Organizacion: this.organizacion
         }
      }
      this.$axios.post('/',objRequestListaBurosOrganizacion,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.reportes=response.data.objContenido
            this.$vs.loading.close('#tabla > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaBurosOrganizacion',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    listaBurosEjecutivo(){
      this.$vs.loading({
          container: '#tabla',
          scale: 0.6
        })
      let self=this
      this.reportes=[]
      // this.organizacion={}
      var objRequestListaBurosEjecutivo = {
        strApiKey: this.key,
        strMetodo: 'ListaBurosEjecutivo',
        objEjecutivo: {
           EMail: this.ejecutivo
         }
      }
      this.$axios.post('/',objRequestListaBurosEjecutivo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.reportes=response.data.objContenido
            this.$vs.loading.close('#tabla > .con-vs-loading')
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaBurosEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    listaSupervisados(){
      let self=this
      var objRequestListaSupervisados = {
        strApiKey: this.key,
        strMetodo: 'ListaSupervisados',
      }

      this.$axios.post('/',objRequestListaSupervisados,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.ejecutivos= response.data.objContenido
            this.ejecutivos.push(this.$store.state.AppActiveUser)
            this.ejecutivo= this.$store.state.AppActiveUser.EMail
            this.listaBurosEjecutivo()
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaSupervisados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    listaOrganizaciones(){
      let self=this
      var objRequestListaOrganizaciones = {
        strApiKey: this.key,
        strMetodo: 'ListaOrganizaciones',
      }

      this.$axios.post('/',objRequestListaOrganizaciones,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
          this.organizaciones=response.data.objContenido
        }else {
          this.$vs.notify({
            title:'Ocurrio un error en ListaOrganizaciones',
            text:response.data.strError,
            color:'danger',
            position:'top-right'
          })
        }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login').catch(() => {})
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>



.vs-table--search-input {
    border: 1px solid #00000033 !important;
  
   
}
.vs-table--header{
  margin-bottom: 50px !important;
  position: relative;
}
</style>
