<template lang="html">
  <div class="">
    <vx-card  class="formulario">
      <span class="card__header"><h4 class="titulo">Generales</h4></span>
      <vs-row class="row w-full">
        <div class="flex flex-wrap items-center">
          <vs-avatar :src="'https://cotizador.socasesores.com/uploads/'+activeUserInfo.Imagen" size="70px" class="mr-4 mb-4" />
          <div>
            <button class="btn btn-second-line" @click="seleccionarImg()" v-if="soc">Subir imagen</button>
            <input class="vs-input " type="file" hidden id="my_file" @change="obtenerImagen"></vs-input>
            <p class="span-placeholder mt-2" v-if="soc">Permitido JPG, GIF or PNG. Tamaño máximo 800kB</p>
          </div>
        </div>
        <div class="row w-full" vs-w="12" vs-justify="space-between">
          <h6 class="subtitulo">Datos personales</h6>
          <div class="col-md-3" >
            <span class="span-placeholder">Nombre </span>
            <input class="vs-input " v-model="nombre" :readonly="!soc"></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">Correo electrónico</span>
            <input class="vs-input " v-model="correo" readonly></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">Contraseña</span>
            <input class="vs-input" v-model="contraseña" type="password" :readonly="!soc"></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">Teléfono</span>
            <input class="vs-input" v-model="telefono" :readonly="!soc" maxlength="10" minlength="10"></vs-input>
          </div>
        </div>
        <div class="row w-full" vs-w="12" vs-justify="space-between" v-if="soc">
          <div class="col-md-3" >
            <span class="span-placeholder">Web</span>
            <input class="vs-input"  v-model="web"></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">RFC del ejecutivo</span>
            <input class="vs-input" v-model="ejecutivoRfc" v-mask="'AAAA######NNN'"></vs-input>
          </div>  
        </div>
        <div class="row w-full">
        <h6 class="subtitulo" v-if="soc">Datos Oficina</h6>
        </div>
        <div class="row" vs-w="12" vs-justify="space-between" v-if="soc">
          <div class="col-md-3" >
            <span class="span-placeholder">Nombre</span>
            <input class="vs-input "  v-model="oficinaNombre" v-if="soc"></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">RFC</span>
            <input class="vs-input" v-model="oficinaRfc" v-mask="'AAA######NNN'"></vs-input>
          </div>
          <div class="col-md-3" >
            <span class="span-placeholder">Teléfono</span>
            <input class="vs-input"  v-model="oficinaTelefono" maxlength="10" minlength="10"></vs-input>
          </div>
        </div>
        <div class="row w-full">
        <div class="col-md-3">
        <button class="btn btn-primary" type="border" @click="actualizarInfo()" v-if="socBoton" >Actualizar perfil</button>
        </div>
        </div>
      </vs-row>
    </vx-card>
  </div>

</template>

<script>
import md5 from 'js-md5';
export default {
  data () {
    return {
      nombre: this.$store.state.AppActiveUser.Nombre,
      correo: this.$store.state.AppActiveUser.EMail,
      contraseña:'',
      telefono:this.$store.state.AppActiveUser.Telefono,
      web:this.$store.state.AppActiveUser.Web,
      ejecutivoRfc:this.$store.state.AppActiveUser.EjecutivoRfc,
      img:null,
      key:'',
      oficinaRfc:this.$store.state.AppActiveUser.OficinaRfc,
      oficinaNombre:this.$store.state.AppActiveUser.OficinaNombre,
      oficinaTelefono:this.$store.state.AppActiveUser.OficinaTelefono,
      soc:false,
      inmobiliaria:false,
      socBoton:false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  watch:{
    telefono:function(newValue) {
      this.telefono=this.telefono.replace(/(\d{2})(\d{4})(\d{4})/, "$1-$2-$3");
    }
  },
  mounted(){
    this.getApikey()
    if(this.$store.state.AppActiveUser.TipoEjecutivoV3=='Inmo'){
      this.inmobiliaria=true
    }else{
      this.soc = true
    }
    if(localStorage.getItem('login')){
      // this.socBoton = false
      this.socBoton = true // para visualizar el botón
    }else{
      this.socBoton = true
    }
  },
  methods:{
    actualizarInfo(){
      let self=this
      var objRequestRegistraEjecutivo
      if (this.contraseña == '') {
        objRequestRegistraEjecutivo = {
          strApiKey: this.key,
          strMetodo: "RegistraEjecutivo",
          objEjecutivo: {
            EMail: this.correo,
            Nombre: this.nombre,
            Telefono:this.telefono,
            Web: this.web,
            EjecutivoRfc: this.ejecutivoRfc,
            DatosOficina:{
              Nombre:this.oficinaNombre,
              Rfc:this.oficinaRfc,
              Telefono:this.oficinaTelefono
            }
          }
        }
      }else {
        objRequestRegistraEjecutivo = {
          strApiKey: this.key,
          strMetodo: "RegistraEjecutivo",
          objEjecutivo: {
            EMail: this.correo,
            Nombre: this.nombre,
            Telefono:this.telefono,
            Password: md5(this.contraseña),
            Web: this.web,
            EjecutivoRfc: this.ejecutivoRfc,
            DatosOficina:{
              Nombre:this.oficinaNombre,
              Rfc:this.oficinaRfc,
              Telefono:this.oficinaTelefono
            }
          }
        }
      }
      this.$axios.post('/',objRequestRegistraEjecutivo,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$store.commit('UPDATE_USER_INFO', response.data.objContenido)
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    seleccionarImg(){
      document.getElementById('my_file').click();
    },
    subirImg(){
      let self=this
      let formData = new FormData();
      formData.append('Imagen', this.img);
      formData.append('MAX_FILE_SIZE','9999999')
      formData.append('request', '{"strApiKey": "'+this.key+'","strMetodo": "RegistraEjecutivo","objEjecutivo": {"EMail": "'+this.correo+'"}}');
      this.$axios.post('/',formData,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.$vs.notify({
              title:`Datos guardados exitosamente`,
              position:'top-right'
            })
            this.$store.commit('UPDATE_USER_INFO', response.data.objContenido)
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en RegistraEjecutivo',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    obtenerImagen(e){
      this.img = e.target.files[0];
      this.subirImg()
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login').catch(() => {})
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
