<template lang="html">
  <div id="container">
    <vx-card title="" class="">
           <span class="card__header"><h4 class="titulo">Datos requeridos</h4></span>
      <div class="row w-full">
        <div class="vx-col lg:w-2/5 sm:w-full">
          <span class="span-placeholder" for="reporte">Tipo de reporte</span>
          <select name="cars" id="reporte" v-model="reporte" class="vs-input">
            <option :value='tipo.Id' v-for="tipo in reportes"  :key="">{{tipo.Nombre}}</option>
          </select>
        </div>
        <div class="vx-col lg:w-1/5 sm:w-full">
          <span class="span-placeholder" for="reporte">Fecha inicio</span>
          <datepicker v-model="fechaInicio" :format="format"></datepicker>
        </div>
        <div class="vx-col lg:w-1/5 sm:w-full">
          <span class="span-placeholder" for="reporte">Fecha fin</span>
          <datepicker v-model="fechaFin" :format="format"></datepicker>
        </div>
      </div>
      <br>
      <button class="btn btn-second" @click="getReporte()">Generar reporte</button>
    </vx-card>
    <vs-alert title="No hay resultados" color="warning" active="true" class="mt-5 text-warning alerta" v-if="reporte && items.length == 0"></vs-alert>
    <Ejecutivos v-if="reporte=='ReporteGeneralEjecutivos' && items.length" :items="items"/>
    <Organizaciones v-if="reporte=='ReporteGeneralOrganizaciones' && items.length" :items="items"/>
    <Solicitantes v-if="reporte=='ReporteGeneralSolicitantes' && items.length" :items="items"/>
    <Precalificaciones v-if="reporte=='ReporteGeneralPrecalificaciones' && items.length" :items="items"/>
    <Buro v-if="reporte=='ReporteGeneralConsultasBuro' && items.length" :items="items"/>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import Organizaciones from '@/views/Perfil/ReportesSoc/ReporteOrganizaciones'
import Ejecutivos from '@/views/Perfil/ReportesSoc/ReporteEjecutivos'
import Solicitantes from '@/views/Perfil/ReportesSoc/ReporteSolicitantes'
import Precalificaciones from '@/views/Perfil/ReportesSoc/ReportePrecalificaciones'
import Buro from '@/views/Perfil/ReportesSoc/ReporteBuro'
export default {
  components: {
    Datepicker,
    Organizaciones,
    Ejecutivos,
    Solicitantes,
    Precalificaciones,
    Buro
  },
  data(){
    return{
      format: "yyyy/MM/dd",
      fechaFin:null,
      fechaInicio:null,
      items:[],
      reporte:'ReporteGeneralEjecutivos',
      reportes:[
        { Nombre:'Ejecutivos', Id:'ReporteGeneralEjecutivos'},
        { Nombre:'Organizaciones', Id:'ReporteGeneralOrganizaciones'},
        { Nombre:'Solicitantes', Id:'ReporteGeneralSolicitantes'},
        { Nombre:'Precalificaciones', Id:'ReporteGeneralPrecalificaciones'},
        { Nombre:'Consultas buró', Id:'ReporteGeneralConsultasBuro'},
      ]
    }
  },
  watch: {
    reporte:function(){
      this.items=[]
    },
  },
  mounted(){
    this.getApikey()
  },
  computed:{
  },
  methods:{
    getReporte(){
      this.$vs.loading({
        container: '#container',
        scale: 0.6
      })
      let formatted_fi = this.fechaInicio.getFullYear() + "-" + (this.fechaInicio.getMonth() + 1) + "-" + this.fechaInicio.getDate()
      let formatted_ff = this.fechaFin.getFullYear() + "-" + (this.fechaFin.getMonth() + 1) + "-" + this.fechaFin.getDate()
      let self=this
      var objRequestReporte = {
        strApiKey: this.key,
        strMetodo: this.reporte,
        objParametros: {
           FechaInicio: formatted_fi,
           FechaFin: formatted_ff,
           UsarGeneral:false
       }
      }
      this.$axios.post('/',objRequestReporte,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {
          if(response.data.intEstatus == 0){
            this.items=response.data.objContenido
          }else {
            this.$vs.notify({
              title:'Ocurrio un error en ListaEstados',
              text:response.data.strError,
              color:'danger',
              position:'top-right'
            })
          }
          this.$vs.loading.close('#container > .con-vs-loading')
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
    numberFormat(num){
      return num.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getApikey(){
      if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey){
        this.$router.push('/login');
      }else {
        this.key=JSON.parse(localStorage.getItem('userInfo')).ApiKey;
      }
    },
  }
}
</script>

<style lang="css" scoped>
.vdp-datepicker .vdp-datepicker__calendar{
  position: relative !important;
}
.alerta{
  margin-bottom: 10%;
}
</style>
